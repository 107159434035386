:root {
  --main-background: #ffffff;
  --primary-background: #b7d3e1;
  --secondary-background: #174350;
  --primary-color: #b7d3e1;
  --secondary-color: #de6a2e;
  --primary-header-color: #00778e;
  --secondary-header-color: #de6a2e;
  --primary-card-background: #174350;
  --secondary-card-background: #b7d3e1;
  --primary-card-color: #b7d3e1;
  --secondary-card-color: #de6a2e;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Open Sans" !important;
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow-y: scroll;
}

body::-webkit-scrollbar {
  display: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

select {
  font-family: "Open Sans" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 22px !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */

input[type="number"] {
  -moz-appearance: textfield;
}

.spin {
  display: block;
  margin: auto;
  text-align: center;
  background: var(--primary-background);
}

.spin span {
  display: block;
  text-align: center;
  margin: auto;
  width: 32px;
  height: 32px;
  border: 2px dashed #000;
  border-radius: 50px;
}

.spin span {
  display: block;
  text-align: center;
  margin: auto;
  width: 32px;
  height: 32px;
  border: 2px solid var(--secondary-color);
  border-bottom: 2px solid var(--primary-background);
  border-radius: 50px;
  animation: 1.25s linear infinite spinner;
  animation-play-state: inherit;
  background: var(--primary-background);
}

.spin {
  overflow: hidden !important;
}

.swal2-container {
  background: var(--primary-background);
  width: auto;
  height: auto;
}

.swal2-popup {
  background: var(--primary-background) !important;
}

.swal2-title {
  background: var(--primary-background);
  font-family: "Roboto Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 33px;
  letter-spacing: 0.5px;
  color: var(--secondary-color) !important;
  border-radius: 5px 5px 0px 0px;
}

.swal2-title-warning {
  background: var(--primary-background);
  font-family: "Roboto Bold";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 38px;
  letter-spacing: 0.5px;
  color: var(--secondary-color) !important;
  text-transform: uppercase !important;
}

.swal2-html-container {
  background: var(--primary-background);
}

.swal2-html-container-warning span {
  display: block;
  text-align: center;
  margin: auto;
  font-family: "Roboto Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 33px;
  letter-spacing: 0.5px;
  color: #ffffff;
}

.swal2-html-container-warning span i {
  display: block;
  margin: 10px auto 20px;
  text-align: center;
  font-size: 64px;
}

@keyframes spinner {
  0% {
    transform: translate3d(0%, 0%, 0) rotate(0deg);
    overflow: hidden !important;
  }
  100% {
    transform: translate3d(0%, 0%, 0) rotate(360deg);
    overflow: hidden !important;
  }
}

@font-face {
  font-family: "Roboto Black";
  src: url("./app/assets/fonts/roboto/Roboto-Black.ttf") format("truetype");
}

@font-face {
  font-family: "Roboto Black Italic";
  src: url("./app/assets/fonts/roboto/Roboto-BlackItalic.ttf")
    format("truetype");
}

@font-face {
  font-family: "Roboto Bold";
  src: url("./app/assets/fonts/roboto/Roboto-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Roboto Bold Italic";
  src: url("./app/assets/fonts/roboto/Roboto-Italic.ttf") format("truetype");
}

@font-face {
  font-family: "Roboto Italic";
  src: url("./app/assets/fonts/roboto/Roboto-Italic.ttf") format("truetype");
}

@font-face {
  font-family: "Roboto Light";
  src: url("./app/assets/fonts/roboto/Roboto-Light.ttf") format("truetype");
}

@font-face {
  font-family: "Roboto Light Italic";
  src: url("./app/assets/fonts/roboto/Roboto-LightItalic.ttf")
    format("truetype");
}

@font-face {
  font-family: "Roboto Medium";
  src: url("./app/assets/fonts/roboto/Roboto-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Roboto Medium Italic";
  src: url("./app/assets/fonts/roboto/Roboto-MediumItalic.ttf")
    format("truetype");
}

@font-face {
  font-family: "Roboto Regular";
  src: url("./app/assets/fonts/roboto/Roboto-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Roboto Thin";
  src: url("./app/assets/fonts/roboto/Roboto-Thin.ttf") format("truetype");
}

@font-face {
  font-family: "Roboto Thin Italic";
  src: url("./app/assets/fonts/roboto/Roboto-ThinItalic.ttf") format("truetype");
}

@font-face {
  font-family: "Open Sans Bold";
  src: url("./app/assets/fonts/opensans/open-sans.bold.ttf") format("truetype");
}
