:root {
  --main-background: #ffffff;
  --primary-background: #b7d3e1;
  --secondary-background: #174350;
  --primary-color: #b7d3e1;
  --secondary-color: #de6a2e;
  --third-color: #00778e;
  --primary-header-color: #00778e;
  --secondary-header-color: #de6a2e;
  --primary-card-background: #174350;
  --secondary-card-background: #b7d3e1;
  --primary-card-color: #b7d3e1;
  --secondary-card-color: #de6a2e;
  --primary-footer-background: #b7d3e1;
  --secondary-footer-background: #174350;
  --primary-footer-color: #de6a2e;
  --secondary-footer-color: #2b839c;
  --thrid-footer-color: #b7d3e1;
}

body:has(.overlay-shadow.active) {
  height: calc(100%);
  overflow: hidden;
}

.overlay-shadow {
  height: 100%;
  position: fixed;
  z-index: 0;
  top: 0;
  right: 0;
  background: var(--main-background);
  height: calc(100%);
  overflow: hidden;
  width: 100%;
  transition: 0.4s;
}

.overlay {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 1;
  top: 0;
  right: 0;
  background-color: var(--primary-background);
  overflow-y: hidden;
  transition: 0.4s;
  border-radius: 0px 0px 0px 195px;
}

.overlay-content {
  position: relative;
  top: 59px;
  width: 100%;
  text-align: center;
  margin-top: 30px;
}

.overlay a {
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 30px;
  text-align: center;
  letter-spacing: 0.5px;
  color: var(--primary-header-color);
  display: block;
  transition: 0.5s;
  text-decoration: none;
  padding: 47px 0px;
  border-bottom: 1px solid var(--secondary-color);
  margin: 5%;
  text-align: right;
}

.overlay .overlay-bottom {
  position: absolute;
  top: 75%;
  width: 100%;
}

.overlay-bottom img {
  display: block;
  text-align: center;
  margin: auto;
  width: 156px;
  height: auto;
}

.overlay a:hover,
.overlay a:focus {
  color: #f1f1f1;
}

.overlay .closebtn {
  position: absolute !important;
  top: 0%;
  padding: 0%;
  margin: 0%;
  border: hidden;
}

.closebtn i {
  vertical-align: super;
  color: var(--secondary-color) !important;
}

.header {
  position: relative;
  width: auto;
  height: calc(100%);
  overflow: hidden;
  background: linear-gradient( 89.86deg, var(--primary-color) 49.55%, rgba(233, 28, 67, 0.3) 97.3%);
  border-radius: 0px 0px 200px 0px;
}

.header:has(.nav-link.second.active) {
  background: var(--primary-background) !important;
}

.navbar-img {
  position: absolute;
  top: 0%;
  right: 0%;
  bottom: 0%;
  left: 26%;
  width: calc(100%);
  height: calc(100%);
  z-index: -1;
  overflow: hidden;
  border-radius: 0px 0px 200px 0px !important;
}

.navbar-img img {
  position: relative;
  bottom: -1%;
  left: 0%;
  right: -5%;
  top: 0%;
  display: block;
  text-align: center;
  margin: auto;
}

.navbar-brand {
  margin: 0% 5% !important;
}

.navbar-brand-title {
  font-family: "Roboto Bold";
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 30px;
  text-align: center;
  letter-spacing: 0.5px;
  color: var(--primary-header-color);
}

.nav-link {
  display: block;
  font-style: normal;
  font-family: "Roboto bold";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 30px;
  font-size: var(--bs-navbar-brand-font-size);
  line-height: 30px;
  color: var(--primary-header-color) !important;
}

.nav-link:hover {
  color: var(--secondary-header-color) !important;
  opacity: 0.9;
}

.nav-link.active {
  color: var(--secondary-header-color) !important;
}

.navbar-title {
  display: block;
  text-align: initial;
  font-family: "Open Sans" !important;
  font-style: normal;
  font-weight: 700;
  font-size: 50px;
  line-height: 80px;
  letter-spacing: 1.55172px !important;
  color: #ffffff;
  padding-left: 12.5%;
}

.navbar-title .first {
  font-family: "Roboto Bold";
  font-style: normal;
  font-weight: 700;
  font-size: 52px;
  letter-spacing: 1.55172px;
  line-height: 80px;
}

.navbar-title .last {
  font-family: "Roboto Bold";
  font-style: normal;
  font-weight: 700;
  font-size: 72px;
  letter-spacing: 1.55172px;
  line-height: 80px;
  padding-bottom: 1%;
}

.form-one-step-2 .title {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: auto;
  color: var(--primary-background);
}

.navbar-tel {
  display: block;
  margin: auto;
  text-align: right;
  padding-right: 15%;
}

.navbar-tel a {
  text-decoration: none;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  letter-spacing: 2px;
  color: #ffffff;
  vertical-align: super;
}

.navbar-actions {
  padding: 23px 96px 50px !important;
}

.navbar-button {
  display: block;
  margin: 23px auto 0px;
  text-align: center;
  width: 90% !important;
  max-width: 203px;
  height: 44px;
  max-height: 44px !important;
  padding: 0px !important;
  border-radius: 10px !important;
  font-family: "Roboto Bold" !important;
  font-style: normal;
  font-weight: 700;
  font-size: 22px !important;
  line-height: 20px;
  letter-spacing: 0.5px;
  background: var(--secondary-background) !important;
  color: var(--primary-color) !important;
}

.navbar .navbar-mobile {
  display: none;
}

.navbar-mobile button {
  border: none;
  display: block;
  padding: 0%;
  margin: 0%;
  text-align: center;
}

.navbar-mobile button i {
  color: var(--secondary-color) !important;
}

.body-height {
  height: max-content;
  min-height: max-content;
}

.card {
  margin: 0% 2.5%;
  border: hidden !important;
  border-radius: 5px !important;
  min-height: 100%;
}

.card-body {
  background: var(--primary-card-background);
  border-radius: 0px 0px 5px 5px !important;
}

.card-img {
  background: var(--primary-background);
  border-radius: 5px 5px 0px 0px !important;
}

.card img {
  display: block;
  margin: auto;
  text-align: center;
}

.card-title {
  font-family: "Roboto Bold Italic";
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.5px;
  color: var(--primary-color);
}

.card-text {
  font-family: "Roboto Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  letter-spacing: 0.5px;
  color: var(--secondary-color);
  min-height: 40px;
  max-height: 40px;
}

.card-text a {
  font-size: 12px;
  line-height: 14px;
  color: var(--secondary-color);
}
.card-text a:hover,
.card-text a:focus {
  color: var(--secondary-color);
  opacity: 0.9;
}

.card-text .tel {
  display: block;
  font-family: "Roboto Bold";
}

.select-title {
  font-family: "Roboto Italic";
  font-style: italic;
  font-weight: 700;
  font-size: 22px;
  line-height: 26px;
  color: #000000;
}

.billing-title {
  font-family: "Roboto Bold";
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 28px;
  letter-spacing: -0.18px;
  color: var(--secondary-color);
}

.form-title {
  font-family: "Roboto Bold";
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 26px;
  color: #333333;
}

.form-subtitle {
  font-family: "Roboto Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: -0.18px;
  color: #606060;
}

.form-label {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  color: #606060;
}

.form-title-billing {
  font-family: "Open Sans Bold";
  font-style: normal;
  font-weight: 700;
  font-size: 52px;
  line-height: 28px;
  text-align: center;
  letter-spacing: -0.18px;
  color: var(--primary-background);
  margin: 4% auto;
}

.form-dialog {
  display: block;
  margin: auto;
  text-align: center;
  width: 100%;
  height: 100%;
  padding: 20px 40px;
  background: #fecc08;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
}

.form-dialog-title {
  display: block;
  text-align: left;
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 28px;
  letter-spacing: -0.18px;
  color: var(--primary-background);
}

.form-dialog-subtitle {
  display: block;
  margin: auto;
  text-align: left;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #333333;
}

.form-dialog-icons {
  text-align: left;
  padding: 5px !important;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #333333;
}

.btn-form.btn-outline-warning {
  background: #ffffff;
  border: 1px solid var(--secondary-color);
  color: var(--secondary-color) !important;
  border-radius: 10px;
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 20px;
  width: 100%;
  min-height: 48px;
}

.btn-form.btn-outline-warning:hover {
  color: #ffffff !important;
  border: 1px solid var(--main-background) !important;
  background: var(--secondary-color) !important;
}

.btn-form-dialog {
  display: block;
  background: #ffffff;
  border: 1px solid #fecc08;
  border-radius: 10px;
  min-height: 60px;
  width: 100%;
  font-family: "Roboto Bold";
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.5px;
  color: #fecc08;
}

.btn-form.btn-warning {
  font-family: "Nunito Sans" !important;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.5px;
  border-radius: 10px !important;
  width: 100%;
  min-height: 60px;
  background: var(--secondary-color) !important;
  border: 1px solid #ffffff;
  color: #ffffff !important;
}

.btn-form:hover {
  color: #fff !important;
}

.btn-form.btn-warning:disabled {
  background: #606060 !important;
  border-radius: 10px !important;
  border: hidden;
  color: #ffffff;
  opacity: unset;
}

.btn-form-cancel {
  border: hidden !important;
  border-radius: 4px !important;
  font-style: normal;
  font-weight: 700 !important;
  font-size: 22px;
  line-height: 34px;
  width: 100% !important;
  text-decoration: underline !important;
  letter-spacing: 0.5px;
}

.btn-form-cancel:hover {
  color: #ffffff !important;
}

.btn-pag-left {
  display: block;
  margin: auto;
  text-align: right;
}

.btn-pag-left i {
  color: var(--third-color) !important;
  font-weight: 700;
  font-size: 10px;
}

.pag-count {
  color: var(--secondary-color) !important;
  font-weight: 700;
  padding: 4.5px 0px;
  font-size: medium !important;
}

.btn-pag-right {
  display: block;
  margin: auto;
  text-align: left;
}

.btn-pag-right i {
  color: var(--third-color) !important;
  font-size: 10px;
}

.footer {
  position: relative;
  width: auto;
  height: auto;
  left: 0%;
  right: 0%;
  top: 0%;
  bottom: 0%;
  background: var(--primary-footer-background);
  padding-left: 10px;
}

.footer-title {
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 35px;
  letter-spacing: 2px;
  color: var(--primary-footer-color);
}

.footer .footer-subtitle {
  font-family: "Roboto Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  letter-spacing: 0.5px;
  color: var(--secondary-footer-color);
}

.footer-second .footer-subtitle {
  font-family: "Roboto Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  letter-spacing: 0.5px;
  color: #ffffff;
}

.footer-subtitle-segundo {
  font-family: "Roboto Bold";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 30px;
  /* identical to box height, or 188% */
  letter-spacing: 0.5px;
  color: var(--secondary-footer-color);
}

.footer-second {
  position: relative;
  width: auto;
  height: auto;
  background: var(--secondary-footer-background);
  padding: 1%;
  align-content: space-between;
  vertical-align: super;
}

.i-color {
  color: var(--primary-color) !important;
}

.mapa {
  width: 100%;
  height: 100%;
  padding: 5%;
}

@media screen and (width <=3440px) {
  .navbar-title .first {
    font-size: 140px;
    line-height: 140px;
    letter-spacing: 1.25px;
  }
  .navbar-title .last {
    font-size: 200px;
    line-height: 180px;
  }
  .nav-link {
    font-size: large;
  }
  .navbar-img img {
    bottom: -5%;
  }
}

@media screen and (width <=2560px) {
  .navbar-title .first {
    font-size: 140%;
    line-height: 80px;
    letter-spacing: 1.25px;
  }
  .navbar-title .last {
    font-size: 200%;
    line-height: 80px;
  }
  .nav-link {
    font-size: 22px !important;
  }
  .navbar-img img {
    bottom: -5%;
  }
}

@media screen and (width <=1440px) {
  .navbar-brand {
    font-size: 15px;
  }
  .navbar-title {
    display: block;
    margin: auto;
  }
  .navbar-title .first {
    font-size: 65px;
    letter-spacing: 1.55172px;
    line-height: 80px;
  }
  .navbar-title .last {
    font-size: 95px;
    letter-spacing: 1.55172px;
    line-height: 80px;
    padding-bottom: 1%;
  }
  .card-title {
    font-size: medium;
  }
  .card-text {
    font-size: 0.7rem;
  }
}

@media screen and (height <=1440px) {
  .body-height {
    min-height: 565px;
    height: max-content;
  }
}

@media screen and (width <=1024px) {
  .nav-link {
    font-size: 15px !important;
  }
  .navbar-brand-title {
    font-size: 15px;
  }
  .navbar-brand {
    font-size: 15px !important;
  }
  .navbar-brand img {
    width: 127px;
    height: 77px;
  }
  .navbar-title {
    display: block;
    margin: auto;
  }
  .navbar-title .first {
    font-size: 48px;
    letter-spacing: 1.5px;
    line-height: 80px;
  }
  .navbar-title .last {
    font-size: 70px;
    letter-spacing: 2px;
    line-height: 80px;
    padding-bottom: 1%;
  }
  .navbar-actions {
    padding: 20px 50px !important;
  }
  .card-title {
    font-size: medium;
  }
  .card-text {
    font-size: 0.55rem;
  }
  .footer-second .footer-subtitle.hidden {
    position: absolute;
    right: 15%;
  }
}

@media screen and (width <=912px) {
  .nav-link {
    font-size: small !important;
  }
  .navbar-brand {
    font-size: 15px !important;
  }
  .navbar-title {
    display: block;
    margin: auto;
  }
  .navbar-title .first {
    font-size: 42px;
    letter-spacing: 1.5px;
    line-height: 50px;
  }
  .navbar-title .last {
    font-size: 60px;
    letter-spacing: 2px;
    line-height: 50px;
  }
  .navbar-actions {
    padding: 75px 96px !important;
  }
  .card img {
    height: 100% !important;
  }
  .card-title {
    font-size: medium;
  }
  .card-text {
    font-size: 0.55rem;
    line-height: 100%;
  }
}

@media screen and (height <=820px) {
  .navbar-title {
    display: block;
    margin: auto;
  }
  .navbar-title .first {
    font-size: 40px;
    letter-spacing: 100%;
    line-height: 40px;
  }
  .navbar-title .last {
    font-size: 55px;
    letter-spacing: 2px;
    line-height: 100%;
  }
  .nav-link {
    font-size: medium !important;
  }
  .card img {
    height: 100% !important;
  }
  .card-title {
    font-size: medium;
    line-height: 20px;
  }
  .card-text {
    font-size: 0.55rem;
    line-height: 100%;
  }
  .footer-second {
    padding-top: 5px !important;
    padding-bottom: 0px !important;
    vertical-align: super !important;
  }
  .footer-second h4.footer-subtitle {
    font-size: 15px !important;
    letter-spacing: 0.5px !important;
  }
}

@media screen and (width <=820px) {
  .navbar-brand img {
    width: 127px;
    height: 77px;
    margin: auto 5px;
  }
  .navbar-title {
    display: block;
    margin: auto;
  }
  .navbar-title .first {
    font-size: 38px;
    letter-spacing: 1.5px;
    line-height: 45px;
  }
  .navbar-title .last {
    font-size: 55px;
    letter-spacing: 2px;
    line-height: 45px;
  }
  .nav-link {
    font-size: 12px !important;
  }
  .navbar-brand-title {
    font-size: 12px !important;
  }
  form .hidden {
    display: none !important;
  }
  .form-one-step-1 .col-md-3,
  .form-one-step-2 .col-md-3,
  .form-one-step-2 .col-md-4 {
    width: 240px !important;
  }
  .form-two-step-1 .col-md-2,
  .form-two-step-1 .col-md-3,
  .form-two-step-1 .col-md-4,
  .form-two-step-1 .col-md-9 {
    width: 360px !important;
  }
  .card img {
    height: 100% !important;
  }
  .card-title {
    font-size: medium;
    line-height: 20px;
  }
  .card-text {
    font-size: 0.55rem;
    line-height: 100%;
  }
  .footer-second {
    padding-top: 5px !important;
    padding-bottom: 0px !important;
    vertical-align: super !important;
  }
  .footer-second h4.footer-subtitle {
    font-size: 15px !important;
    letter-spacing: 0.5px !important;
  }
}

@media screen and (width <=768px) {
  .header {
    width: auto;
  }
  .navbar-brand-title {
    font-size: 11px !important;
  }
  .nav-link {
    font-size: 11px !important;
  }
  .navbar-title {
    display: block;
    margin: auto;
  }
  .navbar-title .first {
    font-size: 34px;
    letter-spacing: 1.555px;
    line-height: 40px;
  }
  .navbar-title .last {
    font-size: 50px;
    letter-spacing: 2px;
    line-height: 100%;
  }
  .navbar-button {
    display: block !important;
    margin-left: auto !important;
    margin-right: auto !important;
    align-self: center !important;
  }
  .card img {
    height: 100% !important;
  }
  .card-title {
    font-size: medium;
    line-height: 20px;
  }
  .card-text {
    font-size: 0.55rem;
    line-height: 100%;
  }
  .form-title-billing {
    font-size: 35px;
  }
  .form-one-step-1 .col-12,
  .form-two-step-1 .col-12 {
    width: 50% !important;
  }
  .form-one-step-1 .col-md-3 {
    width: 33% !important;
  }
  .form-one-step-2 .col-12 {
    width: 33% !important;
  }
  .footer-second {
    padding-top: 5px !important;
    padding-bottom: 0px !important;
    vertical-align: super !important;
  }
  .footer-second h4.footer-subtitle {
    font-size: 15px !important;
    letter-spacing: 0.5px !important;
  }
  .footer-second h4.footer-subtitle.hidden {
    right: 10%;
  }
}

@media screen and (height <=768px) {
  .nav-link {
    font-size: small !important;
  }
  .navbar-title {
    display: block;
    margin: auto;
  }
  .navbar-title .first {
    font-size: 38px;
    letter-spacing: 100%;
    line-height: 40px;
  }
  .navbar-title .last {
    font-size: 52px;
    letter-spacing: 2px;
    line-height: 100%;
  }
  .navbar-button {
    display: block !important;
    margin-left: auto !important;
    margin-right: auto !important;
    align-self: center !important;
  }
  .navbar-brand img {
    width: 128px;
    height: 77px;
  }
  .card img {
    height: 100% !important;
  }
  .card-title {
    font-size: medium;
    line-height: 20px;
  }
  .card-text {
    font-size: 0.55rem;
    line-height: 100%;
  }
  .footer-second {
    padding-top: 5px !important;
    padding-bottom: 0px !important;
    vertical-align: super !important;
  }
  .footer-second h4.footer-subtitle {
    font-size: 15px !important;
    letter-spacing: 0.5px !important;
  }
}

@media screen and (width <=540px) {
  .overlay .closebtn {
    font-size: 40px;
    top: 15px;
    right: 15px;
  }
  .header {
    width: auto !important;
    background: var(--primary-background) !important;
    border-radius: 0px 0px 65px 0px;
  }
  .nav-link {
    display: none !important;
  }
  .navbar .navbar-mobile {
    display: block;
    border: none;
  }
  .navbar-brand {
    display: block;
    margin: auto;
    text-align: center;
  }
  .navbar-brand img {
    display: block;
    margin: auto;
    width: 128px;
    height: 80px;
  }
  .navbar-brand .navbar-brand-title {
    display: none;
  }
  .navbar-title .first {
    font-size: 36.5px;
    line-height: 40px;
  }
  .navbar-title .last {
    font-size: 50px;
    line-height: 40px;
  }
  .navbar-actions button {
    display: block !important;
    margin: auto !important;
    text-align: center !important;
  }
  .navbar-img {
    display: none;
  }
  .card img {
    height: 100% !important;
  }
  .card-title {
    font-size: x-large;
    line-height: 100%;
  }
  .card-text {
    font-size: 0.75rem;
    text-align: center;
    line-height: 100%;
  }
  .form-one-step-1 .col-12,
  .form-two-step-1 .col-12 {
    width: 50% !important;
  }
  .form-one-step-1 .col-md-3 {
    width: 33% !important;
  }
  .form-one-step-2 .col-12 {
    width: 33% !important;
  }
  .footer-second {
    padding-top: 5px !important;
    padding-bottom: 0px !important;
    vertical-align: super !important;
  }
  .footer {
    display: block;
    margin: auto;
    text-align: center;
  }
  .footer-second {
    padding-top: 5px !important;
    padding-bottom: 0px !important;
  }
  .footer-second h4.footer-subtitle {
    font-size: small !important;
    letter-spacing: 0.5px !important;
  }
  .footer-second h4.footer-subtitle.hidden {
    display: none !important;
    position: unset;
  }
  .hidden-mobile {
    display: none;
  }
}

@media screen and (height <=540px) {
  .nav-link {
    font-size: 11px !important;
  }
  .navbar-brand {
    display: block;
    margin: auto;
    text-align: center;
  }
  .navbar-brand img {
    display: block;
    margin: auto;
  }
  .navbar-title .first {
    font-size: 27px;
    line-height: 35px;
  }
  .navbar-title .last {
    font-size: 37px;
    line-height: 35px;
  }
  .navbar-tel {
    display: block !important;
  }
  .card img {
    height: 100% !important;
  }
  .card-title {
    font-size: x-large;
    line-height: 100%;
  }
  .card-text {
    font-size: 0.75rem;
    text-align: center;
    line-height: 100%;
  }
  .footer {
    display: block;
    margin: auto;
    text-align: center;
  }
  .footer-second {
    padding-top: 5px !important;
    padding-bottom: 0px !important;
  }
  .footer-second h4.footer-subtitle {
    font-size: small !important;
    letter-spacing: 0.5px !important;
  }
}

@media screen and (width <=425px) {
  .overlay a {
    font-size: 20px;
  }
  .overlay .closebtn {
    font-size: 40px;
    top: 15px;
    right: 15px;
  }
  .navbar-brand {
    font-size: 15px !important;
  }
  .navbar-brand img {
    width: 128px;
    height: 80px;
  }
  .nav-link {
    display: none !important;
  }
  .navbar-title .first {
    font-size: 26px;
  }
  .navbar-title .last {
    font-size: 38px;
  }
  .navbar-actions button {
    display: block !important;
    margin: auto !important;
    text-align: center !important;
  }
  .card-text {
    font-size: x-small;
    line-height: 100%;
  }
  .form-one-step-1 .col-12,
  .form-one-step-2 .col-12,
  .form-two-step-1 .col-12 {
    width: 100% !important;
  }
  .footer {
    display: block;
    margin: auto;
    text-align: center;
  }
  .footer img {
    display: block;
    margin: auto;
    text-align: center;
  }
  .footer-second {
    padding-top: 5px !important;
    padding-bottom: 0px !important;
  }
  .footer-second h4.footer-subtitle {
    font-size: 13.5px !important;
    letter-spacing: 0.5px !important;
  }
}

@media screen and (height <=425px) {
  .navbar-img {
    display: block !important;
  }
  .navbar-brand {
    font-size: 15px !important;
  }
  .nav-link {
    display: block !important;
    font-size: x-small !important;
  }
  .navbar-tel {
    display: block !important;
  }
  .navbar-title {
    display: block;
    margin: auto;
    padding-left: 12.5%;
  }
  .navbar-title .first {
    font-size: 28px;
  }
  .navbar-title .last {
    font-size: 38px;
  }
  .card img {
    height: 100%;
  }
  .card-title {
    font-family: "Nunito Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.5px;
    text-transform: uppercase;
  }
  .card-text {
    font-size: 0.55rem;
    line-height: 10px;
  }
  .form-one-step-1 .col-12,
  .form-two-step-1 .col-12 {
    width: 50% !important;
  }
  .form-one-step-1 .col-md-3 {
    width: 33% !important;
  }
  .form-one-step-2 .col-12 {
    width: 33% !important;
  }
  .footer {
    display: block;
    margin: auto;
    text-align: center;
  }
  .footer img {
    display: block;
    margin: auto;
    text-align: center;
  }
  .footer-second {
    padding-top: 5px !important;
    padding-bottom: 0px !important;
  }
  .footer-second h4.footer-subtitle {
    font-size: 13.5px !important;
    letter-spacing: 0.5px !important;
  }
}

@media screen and (height <=375px) {
  .nav-link {
    font-size: x-small !important;
  }
  .navbar-tel {
    display: none;
  }
  .card-text {
    font-size: x-small;
    line-height: 110%;
  }
  .form-one-step-1 .col-12,
  .form-two-step-1 .col-12 {
    width: 50% !important;
  }
  .form-one-step-1 .col-md-3 {
    width: 33% !important;
  }
  .form-one-step-2 .col-12 {
    width: 33% !important;
  }
  .footer-second {
    padding-top: 5px !important;
    padding-bottom: 0px !important;
    vertical-align: super !important;
  }
  .footer {
    display: block;
    margin: auto;
    text-align: center;
  }
}

@media screen and (width <=375px) {
  .nav-link {
    display: none !important;
  }
  .navbar-brand img {
    display: block !important;
    margin: auto !important;
    text-align: center;
  }
  .navbar-brand {
    font-size: 15px !important;
  }
  .navbar-title .first {
    font-size: 22px;
    line-height: 40px;
  }
  .navbar-title .last {
    font-size: 32px;
    line-height: 40px;
  }
  .navbar-actions {
    padding: 20px auto 50px !important;
  }
  .navbar-button {
    position: absolute;
    left: 0%;
    right: 5%;
    top: 60%;
    bottom: 15%;
    display: block;
    margin: auto;
    text-align: center;
    max-width: 190px !important;
  }
  .card-text {
    line-height: 10px;
    font-size: x-small;
  }
  .footer {
    display: block;
    margin: auto;
    text-align: center;
  }
  .footer img {
    display: block;
    margin: auto;
    text-align: center;
  }
  .footer-second {
    padding-top: 5px !important;
    padding-bottom: 0px !important;
  }
  .footer-second h4.footer-subtitle {
    font-size: 13.5px !important;
    letter-spacing: 0.5px !important;
  }
}

@media screen and (width <=280px) {
  .overlay {
    border-radius: 0px 0px 0px 50px !important;
  }
  .overlay a {
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    line-height: 30px;
    text-align: center;
    letter-spacing: 0.5px;
    color: #ffffff;
    display: block;
    text-align: right;
    transition: 250ms;
  }
  .navbar-title .first {
    font-size: 17.5px;
    line-height: 20px;
  }
  .navbar-title .last {
    font-size: 24px;
    line-height: 20px;
  }
  .navbar-actions .navbar-button {
    position: absolute;
    left: 0%;
    right: 8%;
    top: 60%;
    bottom: auto;
    display: block !important;
    margin: 20px auto !important;
    text-align: center !important;
    width: 180px !important;
    max-width: 180px !important;
  }
  .form-title {
    font-size: medium;
  }
  .form-one-step-1 .col-12,
  .form-one-step-2 .col-12,
  .form-two-step-1 .col-12 {
    width: 100% !important;
  }
  .footer-second {
    padding-top: 5px !important;
    padding-bottom: 0px !important;
    vertical-align: super !important;
  }
  h4.pag-count {
    padding-top: 30%;
    font-size: small !important;
  }
  .footer-second .footer-dev-title h4 {
    font-size: 0.46rem !important;
    font-weight: bolder;
  }
}

@media screen and (height <=280px) {
  .nav-link {
    font-size: xx-small !important;
  }
  .navbar-title .first {
    font-size: 28px;
    line-height: 40px;
  }
  .navbar-title .last {
    font-size: 40px;
    line-height: 35px;
  }
  .navbar-actions .navbar-button {
    display: block !important;
    text-align: center !important;
    width: 180px !important;
    min-width: 180px !important;
    font-size: 16px;
  }
}
